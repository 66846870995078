import React, { useEffect, useState } from "react";
import { Card, CardGroup } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
//import getCpuUsage from "./cpuUsageService";

function MonitorVariable(){

    const [cpuUsageValue, setCpuUsageValue ]= useState(0)

    // useEffect(()=>{
    //     getCpuUsage.then(onGetCpuUsageSuccess).catch(onGetCpuUsageError)
    // }, [])

    // const onGetCpuUsageSuccess = (response) =>{
    //     console.log("CPU Usage Value recieved successfully", response)

    //     setCpuUsageValue((prevState) =>{
    //         let ps = { ...prevState };
    //         ps = response.item.value
    //         return ps;
    //     })
    // }

    // const onGetCpuUsageError = (err) =>{
    //     console.log("Error in recieving CPU Usage", err)
    // }


    return(
        <>  
            <Card className="col-6 mx-auto m-5">
                <CardHeader className="">
                    <p className="text-center">CPU Usage Monitoring</p>
                    <p></p>
                </CardHeader>
                <CardGroup className="center">
                    <p className="m-2">
                        Value: {cpuUsageValue}
                    </p >
                </CardGroup>
            </Card> 
        </>
    )
}
export default MonitorVariable